import React from 'react'
import { Fade } from 'react-awesome-reveal'

const BenefitsSection = ({ title, benefits }) => {
  return (
    <section className='bg-secondary mt-6 pt-8 pb-8 lg:pb-12'>
      <div className='container'>
        <h3 className='text-center text-white m-0'>
          <span className='text-white'>Benefits</span> of {title}
        </h3>
        <Fade cascade={true}>
          <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
            {benefits.map((benefit, index) => (
              <Fade delay={index * 300} fraction='0.5'>
                <div className='text-center text-white px-4 md:px-8'>
                  <h4 className='text-white'>{benefit.title}</h4>
                  <p className='mt-4 text-white'>{benefit.description}</p>
                </div>
              </Fade>
            ))}
          </div>
        </Fade>
      </div>
    </section>
  )
}

BenefitsSection.defaultProps = {
  title: 'title',
  benefits: [
    {
      title: 'benefit 1',
      description: 'benefit 1 blurb',
    },
  ],
}

export default BenefitsSection
