import React, { useState } from 'react'
import wdcSvg from '../../assets/images/services-page/wdc-icon.svg'
import wdcSvgPrimary from '../../assets/images/services-page/wdc-icon-primary.svg'

const Faq = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <article
      className={`container border border-gray-400 ${
        !expanded && 'flex flex-row items-center'
      } rounded-md p-2 mt-4`}
    >
      <button
        onClick={() => setExpanded(!expanded)}
        className='w-full focus:outline-none'
      >
        <div
          className={`${
            expanded && 'text-primary'
          } cursor-pointer flex flex-row text-left`}
        >
          <img
            src={expanded ? wdcSvgPrimary : wdcSvg}
            className='mr-6 self-center'
            alt=''
          />
          <p
            className={`${
              expanded && 'text-primary'
            } p-0 m-0 self-center font-semibold`}
          >
            {question}
          </p>
        </div>
        <p
          className={`${
            expanded && 'not-sr-only'
          } sr-only mx-0 my-2 px-4 md:px-8 xl:px-12 text-center`}
        >
          {answer}
        </p>
      </button>
    </article>
  )
}

Faq.defaultProps = {
  question: 'Question 1?',
  answer: 'answer answer answer answer answer answer answer answer',
}
export default Faq
