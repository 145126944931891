import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Fade } from 'react-awesome-reveal'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ServiceContentSection = ({ heading, image, content, enterRight }) => {
  const imagePath = getImage(image)
  const splitHeading = heading.split('  ')

  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className='text-xl'>{children}</h3>
      ),
    },
  }

  return (
    <article className='container'>
      <Fade
        duration={500}
        damping={0.5}
        fraction={0.5}
        direction={enterRight ? 'right' : 'left'}
        triggerOnce
      >
        <div className='flex flex-row flex-wrap justify-center items-center mt-4 md:mt-12 lg:mt-12 xl:w-11/12 mx-auto p-0'>
          <h2 className='m-0 mt-8 xl:mt-0 text-center w-11/12 sm:w-full'>
            {splitHeading.map((line, index) => {
              if (index !== splitHeading.length - 1) {
                return (
                  <>
                    {line}
                    <br />
                  </>
                )
              } else {
                return <>{line}</>
              }
            })}
          </h2>
          <div className='text-center xl:self-start m-0 px-4 pb-4 md:px-8 md:mt-6 xl:mt-8 xl:px-12 lg:w-80vw xl:w-1/2'>
            {content?.raw &&
              documentToReactComponents(JSON.parse(content.raw), options)}
          </div>
          <div className='xl:self-start flex justify-center items-center xl:items-start order-first xl:order-3 lg:h-96 xl:h-auto mt-0 xl:mt-12 xl:pb-12 xl:w-1/2'>
            <GatsbyImage
              image={imagePath}
              className='w-full max-h-52 md:max-h-72 lg:max-h-full xl:w-3/5 xl:rounded-xl xl:shadow-xl'
              alt={image ? image.title : ''}
            />
          </div>
        </div>
      </Fade>
    </article>
  )
}

ServiceContentSection.defaultProps = {
  heading: 'Section Title',
  content: 'Section content',
  imageRight: true,
}

export default ServiceContentSection
