import React from 'react'
import ArticlesSection from '../components/sections/ArticlesSection'
import BenefitsSection from '../components/sections/BenefitsSection'
import FaqSection from '../components/sections/FaqSection'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ServiceContentSection from '../components/sections/ServiceContentSection.js'
import WeDoCtoBanner from '../components/sections/WeDoCtoBanner'

const ServiceTemplate = ({ data: { contentfulService: service } }) => {
  const {
    topSectionHeading,
    topSectionContent,
    topSectionImage,
    midSectionHeading,
    midSectionContent,
    midSectionImage,
    faqs,
  } = service

  const pageData = service.page[0]
  const articlesData = pageData.relatedTags

  return (
    <Layout page={pageData} socialIcons>
      <div className='container mt-8 w-11/12 xl:w-full sm:w-full shadow-md'>
        <ServiceContentSection
          heading={topSectionHeading}
          content={topSectionContent}
          image={topSectionImage}
          enterRight
        />
        {pageData.benefits && (
          <BenefitsSection
            title={pageData.benefits.title}
            benefits={pageData.benefits.benefits}
          />
        )}
        <ServiceContentSection
          heading={midSectionHeading}
          content={midSectionContent}
          image={midSectionImage}
          enterRight={false}
        />
        {faqs.length > 0 && <FaqSection faqs={faqs} />}
      </div>
      {/*<WeDoCtoBanner />*/}
      <ArticlesSection title='Related' articlesData={articlesData} />
    </Layout>
  )
}

export const query = graphql`
  query singleServiceQuery($id: String) {
    contentfulService(id: { eq: $id }) {
      topSectionHeading
      topSectionImage {
        gatsbyImageData
        title
      }
      topSectionContent {
        raw
      }
      midSectionHeading
      midSectionContent {
        raw
      }
      midSectionImage {
        gatsbyImageData
        title
      }
      faqs {
        answer
        id
        question
      }
      page {
        title
        subtitle
        hero {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
        callToAction
        callToActionLink {
          pageLink
        }
        name
        benefits {
          title
          benefits {
            description
            title
          }
        }
        relatedTags {
          article {
            title
            tileImage {
              gatsbyImageData
            }
            tags {
              name
            }
            publishDate(formatString: "DD MMMM, YYYY")
          }
        }
      }
    }
  }
`

export default ServiceTemplate
