import React from 'react'
import faqsIllustration from '../../assets/images/services-page/faqs-illustration.svg'
import Faq from '../elements/Faq'

const FaqSection = ({ faqs }) => {
  return (
    <article className='container flex flex-col items-center bg-gray-50 px-4 pt-8 pb-9 md:p-12 md:pb-0 mt-8'>
      <h2 className='m-0 text-secondary underline'>Frequently Asked Questions</h2>
      <div className='mt-4 sm:mt-8 w-full md:w-80vw lg:max-w-screen-lg'>
        {faqs.map(faq => {
          return (
            <div key={faq.id}>
              <Faq question={faq.question} answer={faq.answer} />
            </div>
          )
        })}
      </div>
      <img
        src={faqsIllustration}
        className='hidden md:block mt-4 w-52 lg:w-96 self-start'
        alt=''
      />
    </article>
  )
}

export default FaqSection
